import React, { useState } from "react";
import classes from "./DonationModal.module.css";

const DonationModal = ({ startup, onClose, currentUser }) => {
  const [amount, setAmount] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [mouseDownOnBackdrop, setMouseDownOnBackdrop] = useState(false);

  const handleAmountChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    setAmount(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    const amountNum = parseFloat(amount);

    if (!amount || amountNum <= 0) {
      setError("Please enter a valid amount");
      setIsLoading(false);
      return;
    }

    if (amountNum < 5) {
      setError("Minimum donation amount is £5");
      setIsLoading(false);
      return;
    }

    try {
      const { getFunctions, httpsCallable } = await import(
        "firebase/functions"
      );
      const functions = getFunctions();
      const createCheckoutSession = httpsCallable(
        functions,
        "createCheckoutSession"
      );

      const result = await createCheckoutSession({
        startupId: startup.id,
        startupOwnerId: startup.ownerId,
        amount: parseFloat(amount),
        message: message,
        currency: "gbp",
        origin: window.location.origin, // Add this line
      });

      if (result.data?.url) {
        window.location.href = result.data.url;
      } else {
        throw new Error("Failed to create checkout session");
      }
    } catch (err) {
      console.error("Payment error:", err);
      setError("Failed to process payment. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleBackdropMouseDown = (e) => {
    if (e.target === e.currentTarget) {
      setMouseDownOnBackdrop(true);
    }
  };

  const handleBackdropMouseUp = (e) => {
    if (e.target === e.currentTarget && mouseDownOnBackdrop) {
      onClose();
    }
    setMouseDownOnBackdrop(false);
  };

  const handleCloseTouch = (e) => {
    e.preventDefault();
    onClose();
  };

  return (
    <div
      className={classes.modalBackdrop}
      onMouseDown={handleBackdropMouseDown}
      onMouseUp={handleBackdropMouseUp}
    >
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            Support {startup.startupName}
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              fill="none"
              viewBox="0 0 24 24"
              onClick={onClose}
              onTouchStart={handleCloseTouch}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>
        </div>

        <form className={classes.interactions} onSubmit={handleSubmit}>
          {isLoading ? (
            <div className={classes.loadingContainer}>
              <span
                className={`material-symbols-outlined ${classes.loadingIcon}`}
              >
                progress_activity
              </span>
            </div>
          ) : (
            <>
              <label htmlFor="amount" className={classes.label}>
                Amount (£)*
              </label>
              <div className={classes.amountContainer}>
                <input
                  id="amount"
                  type="text"
                  value={amount}
                  onChange={handleAmountChange}
                  className={classes.userInput}
                  placeholder="0"
                />
              </div>
              <div className={classes.smallPrint}>
                <p>Minimum donation amount is £5</p>
                <div className={classes.charCount}>{amount.length}/50</div>
              </div>

              <div className={classes.border}></div>

              <label htmlFor="message" className={classes.label}>
                Message (optional)
              </label>
              <textarea
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className={classes.textArea}
                placeholder="Add a message of support..."
                rows={3}
              />
              <div className={classes.charCount}>{message.length}/250</div>
            </>
          )}
        </form>

        {error && <p className={classes.error}>{error}</p>}

        <div className={classes.cardFooter}>
          <button
            type="submit"
            className={`${classes.button} ${isLoading ? classes.loading : ""}`}
            disabled={!amount || isLoading}
            onClick={handleSubmit}
          >
            {isLoading ? (
              <span
                className={`material-symbols-outlined ${classes.loadingIcon}`}
              >
                progress_activity
              </span>
            ) : (
              "Continue to payment"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DonationModal;
